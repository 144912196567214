import { getEmptyUserAdmin, UserAdmin } from "@/models/user_admin";
import { LeadProduct } from "@/models/lead_product";

export interface DepartureHdProduct {
    id: number,
    name: string,
    vendorCode: string,
    type: string,
    brand: string,
    color: string,
    count: number,
    sum: number,
    link: string,
    trackNumber: string,
    createdAt?: string,
    updatedAt?: string,
    leadId: number,
    index: number,
    size: string,
    location: string,
    storeStatus: string,
    refNumber: string,
    hsCode: string,
    sku: string,
    storeStatusHistory: { "date": string, "action": string, "value": string, "userId": number, }[],
    trackNumberIm: string,
    numberOrderIm: string,
    note?: string,
    checkStatus?: string,
    checkNote?: string,
    images: string[],
    deletedAt?: string,
    pfShopName: string,
    pfShopUrl: string,
    sumAed: string,
    eurCost: string,
    courierDepartureId?: number,
    priority: string,
    hdStatus: string,
    leadIds: {
        "id": number,
        "amoId": number
    },
    invoice_flag?: boolean,
    cdIds?: [],
    invoice?: string,
    unPinned?: string,
    unPinned2?: string,
}

export function getEmptyDepartureHdProduct(): DepartureHdProduct {
    return {
        id: 0,
        name: "",
        vendorCode: "",
        type: "",
        brand: "",
        color: "",
        count: 0,
        sum: 0,
        link: "",
        trackNumber: "",
        createdAt: "",
        updatedAt: "",
        leadId: 0,
        index: 0,
        size: "",
        location: "",
        storeStatus: "",
        refNumber: "",
        hsCode: "",
        sku: "",
        storeStatusHistory: [],
        trackNumberIm: "",
        numberOrderIm: "",
        note: "",
        checkStatus: "",
        checkNote: "",
        images: [],
        deletedAt: "",
        pfShopName: "",
        pfShopUrl: "",
        sumAed: "",
        eurCost: "",
        courierDepartureId: 0,
        priority: "",
        leadIds: {
            id: 0,
            amoId: 0
        },
        hdStatus: '',
    };
}


export interface DepartureHd {
    id: number
    responsibleId: number
    eurSum: string
    aedSum: string
    departureCost: number
    status: string
    checkSum: string
    note: string
    courierId: number
    priority: string
    handOverTs?: number | string
    departureTs?: number | string
    arrivingTs?: number | string
    leadProductsIds: number[]
    leadProducts: DepartureHdProduct[]
    lead_products: DepartureHdProduct[]
    airportId?: number
    responsibleUser: UserAdmin
    courierUser?: UserAdmin
    actualCourierPhone?: string
    createdAt: string
    action?: string,
    photos?: {
        [x: string]: {
            url: string
            path: string
            name: string
        }
    }
}

export function getEmptyDepartureHd() {
    return {
        id: 0,
        responsibleId: 0,
        eurSum: "",
        aedSum: "",
        departureCost: 0,
        status: "",
        checkSum: "",
        note: "",
        courierId: 0,
        priority: "",
        handOverTs: 0,
        departureTs: 0,
        arrivingTs: 0,
        leadProductsIds: [],
        leadProducts: [],
        lead_products: [],
        airportId: 0,
        responsibleUser: getEmptyUserAdmin(),
        courierUser: getEmptyUserAdmin(),
        createdAt: "",
    };
}

export interface DepartureHdOrigin {
    id: number
    responsible_id: number
    eur_sum: string
    aed_sum: string
    departure_cost: number
    status: string
    check_sum: string
    note: string
    courier_id: number
    priority: string
    hand_over_ts?: number | string
    departure_ts?: number | string
    arriving_ts?: number | string
    lead_products_ids: number[]
    lead_products: DepartureHdProduct[]
    airport_id?: number
    responsible_user: UserAdmin
    courier_user?: UserAdmin
    actual_courier_phone?: string
    created_at: string
    action?: string,
    photos?: {
        [x: string]: {
            url: string
            path: string
            name: string
        }
    }
}

export function getEmptyDepartureHdOrigin(): DepartureHdOrigin {
    return {
        id: 0,
        responsible_id: 0,
        eur_sum: "",
        aed_sum: "",
        departure_cost: 0,
        status: "",
        check_sum: "",
        note: "",
        courier_id: 0,
        priority: "",
        hand_over_ts: 0,
        departure_ts: 0,
        arriving_ts: 0,
        lead_products_ids: [],
        lead_products: [],
        airport_id: 0,
        responsible_user: getEmptyUserAdmin(),
        courier_user: getEmptyUserAdmin(),
        actual_courier_phone: "",
        created_at: "",
    };
}


export interface DepartureHdFilters {
    hdIdPinned: number[],
    status: { [x: string]: string },
    productStatuses: { [x: string]: string },
    productUpdateStatuses: string[],
}

export function getEmptyDepartureHdFilters(): DepartureHdFilters {
    return {
        hdIdPinned: [],
        status: {},
        productStatuses: {},
        productUpdateStatuses: [],
    };
}

export interface DeliveryServiceDeparture {
    id: number
    createdAt: string
    updatedAt: string
    deletedAt: any
    leadId: number
    priority: string
    rfDepartureTs?: string
    trackNumberRf?: string
    deliveryCostRf?: number | null | undefined
    address?: string
    userPriority: string
    responsibleId?: number
    status: string
    note?: string
    logisticianId?: number
    atRussianStoreSince?: any
    invoiceData?: {
        url: string
        name: string
    },
    productLeads: DepartureLead
    product_leads: DepartureLead
    responsible?: any
}

export function getEmptyDeliveryServiceDeparture(): DeliveryServiceDeparture {
    return {
        id: 0,
        createdAt: "",
        updatedAt: "",
        deletedAt: null,
        leadId: 0,
        priority: "",
        rfDepartureTs: "",
        trackNumberRf: "",
        deliveryCostRf: 0,
        userPriority: '',
        address: "",
        responsibleId: 0,
        status: "",
        note: "",
        logisticianId: 0,
        atRussianStoreSince: null,
        invoiceData: {
            url: '',
            name: '',
        },
        productLeads: getEmptyDepartureLead(),
        product_leads: getEmptyDepartureLead(),
        responsible: null
    }
}

export interface DepartureLead {
    id: number
    name: string
    responsibleUserId: number
    statusId: number
    total: string
    amoId: number
    address: string
    created: string
    trackNumber: string
    slug: any
    createdAt: string
    updatedAt: string
    requestsPassportAt: any
    payCustomDutyAt: any
    customerId: number
    linkPassport: string
    extra: string
    statusesHistory: string
    sumProducts: number
    brandeliveryCommission: number
    discountCertificate: number
    wishitemId: any
    linkPayment: any
    departureId: number
    dealNote: string
    warehouseNote: string
    refNo: string
    direction: string
    purchaseDate: string
    amoStatusesHistory: string
    amoStatusId: number
    deliveryType: string
    departureStatus: string
    departureStatusHistory: any
    handlingType: string
    deletedAt: any
    customsFee: string
    reservedBonuses: number
    refundDeadline: string
    receivedByClientAt: string
    items: LeadProduct[]
    customerRelation: CustomerRelation
}

export function getEmptyDepartureLead(): DepartureLead {
    return {
        id: 0,
        name: "",
        responsibleUserId: 0,
        statusId: 0,
        total: "",
        amoId: 0,
        address: "",
        created: "",
        trackNumber: "",
        slug: "",
        createdAt: "",
        updatedAt: "",
        requestsPassportAt: "",
        payCustomDutyAt: "",
        customerId: 0,
        linkPassport: "",
        extra: "",
        statusesHistory: "",
        sumProducts: 0,
        brandeliveryCommission: 0,
        discountCertificate: 0,
        wishitemId: "",
        linkPayment: "",
        departureId: 0,
        dealNote: "",
        warehouseNote: "",
        refNo: "",
        direction: "",
        purchaseDate: "",
        amoStatusesHistory: "",
        amoStatusId: 0,
        deliveryType: "",
        departureStatus: "",
        departureStatusHistory: [],
        handlingType: "",
        deletedAt: "",
        customsFee: "",
        reservedBonuses: 0,
        refundDeadline: "",
        receivedByClientAt: "",
        items: [],
        customerRelation: getEmptyCustomerRelation(),
    }
}

export interface CustomerRelation {
    id: number
    amoId: number
    name: string
}

export function getEmptyCustomerRelation(): CustomerRelation {
    return {
        id: 0,
        amoId: 0,
        name: "",
    }
}

export interface DepartureHdSearchItem {
    id: number
    courier_name: string
    created_at: string
    pinned: boolean
}