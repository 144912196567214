import {AppTableConfig} from "@/views/Departure/AppTableConfig";
import dateFormatter from "@/shared/dateFormat";
import {DepartureHd} from "@/models/departure_hd";
import {snakeCase} from "lodash";
import {api} from "@/api";

type T = DepartureHd;

export const hdDepartureListTableConfig: (selectAllType: number, filters: { statuses: { [x: string]: string }, hdIdPinned: number[] }) => AppTableConfig<T> = (selectAllType: number, filters) => ({
    columns: [
        {
            column: 'id',
            label: '',
            width: '100px',
            filter: {
                type: 'checkbox',
                column: 'id',
                checkboxSelect: {
                    selectAll: selectAllType != 0,
                    selectAllTypeValue: selectAllType,
                }
            }
        },
        {
            column: 'id',
            label: 'ID HD',
            width: '100px',
            filter: {
                type: 'multiselect-search',
                api: async (search: string) => {
                    return await api.departureHd
                        .getDepartureHdSearch({search_id: Number.parseInt(search ?? ''), pinned_ids: filters.hdIdPinned})
                        .then(value => {
                            if (value.success) {
                                return value.payload?.map(item => ({
                                    label: `${item.id} ${item.courier_name} ${dateFormatter(item.created_at)}`,
                                    value: `${item.id}`,
                                    selected: item.pinned ?? false,
                                })) ?? []
                            }

                            return []
                        })
                }
            },
        },
        {
            column: 'createdAt',
            label: 'Создано',
            formatter: item => dateFormatter(item.createdAt),
            filter: {type: 'date',}
        },
        {
            column: 'responsibleUser',
            label: 'Ответственный',
            formatter: item => item.responsibleUser?.name,
            filter: {type: 'search'},
        },
        {
            column: 'eurSum',
            label: 'Сумма EUR',
            filter: {
                type: 'range-numbers',
                numberRange: ['', ''],
            },
        },
        {
            column: 'aedSum',
            label: 'Сумма AED',
            filter: {
                type: 'range-numbers',
                numberRange: ['', ''],
            },
        },
        {
            column: 'status',
            label: 'Статус HD',
            filter: {
                type: 'multiselect',
                options: Object.keys(filters.statuses ?? []).map(e => ({value: snakeCase(e), label: filters.statuses[e]})),
            },
        },
        {
            column: 'departureCost',
            label: 'Стоимость РУБ',
            filter: {
                type: 'range-numbers',
                numberRange: ['', ''],
            },
        },
        // {
        //   column: 'priority',
        //   label: 'Приоритет',
        // },
        {
            column: 'note',
            label: 'Примечание',
            width: '200px',
            filter: {type: 'search'},
        },
        {
            column: 'action',
            label: '',
            width: '150px'
        },
    ]
})