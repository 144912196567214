import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {api} from "@/api";
import {getEmptyUserAdmin, UserAdmin} from "@/models/user_admin";
import {useRoute} from "vue-router";

type T = UserAdmin;

export const useUserStore = defineStore('user', () => {
    const item = ref<T>(getEmptyUserAdmin())
    const isPending = ref(false)
    const error = ref()
    const roles = ref<{ [key: string]: string }>({});

    const route = useRoute();

    async function userLoad() {
        isPending.value = true;
        const res = await api.getAdminUserMe()
        item.value = res.payload ?? getEmptyUserAdmin();
        roles.value = item.value.role?.admin_access_list ?? []
        error.value = res.error;
        isPending.value = false;

        console.log({...error.value})

        return error.value == null;
    }

    async function userReload() {
        const res = await api.getAdminUserMe()
        item.value = res.payload ?? getEmptyUserAdmin();
        roles.value = item.value.role?.admin_access_list ?? []
        error.value = res.error;

        return res.success;
    }

    function canView(role: string) {
        // if (role == 'payments') return true;
        if (role == 'logout') return true;

        if (role == 'payments') {
            // @ts-ignore
            if (!roles.value['payments']) {
                return false;
            }
            // @ts-ignore
            return [...roles.value['payments']].includes('view') || [...roles.value['payments']].includes('view-all')
        }

        if (role == 'expresso') {
            // @ts-ignore
            if (!roles.value['expresso']) {
                return false;
            }
            // @ts-ignore
            return [...roles.value['expresso']].includes('view') || [...roles.value['expresso']].includes('view-all')
        }

        if (role == 'bonus-config') {
            return canBonusConfigView;

            // // @ts-ignore
            // if (!roles.value['bonus-config']) {
            //     return false;
            // }
            // // @ts-ignore
            // return [...roles.value['bonus-config']].includes('view')
        }

        // @ts-ignore
        return (roles.value[role] != 'none' && roles.value[role] != '' && roles.value[role] != undefined);
    }

    function canCreate(role?: string) {
        if (role == undefined || role == '') {
            return false;
        }

        if (role == 'payments' || role == 'expresso') {
            console.log(`canCreate ${role} ${canPaymentCreate.value}`)
            return canPaymentCreate
        }

        if (role == 'bonus-config') {
            return canBonusConfigCreate ?? false
        }

        if (role == 'bonuses') {
            // @ts-ignore
            return (roles.value['bonuses'] == 'edit');
        }

        // @ts-ignore
        return (roles.value[role] == 'all');
    }

    function canDelete(role: string) {
        if (role == 'payments') return true;
        if (role == 'bonus-config') {
            return canBonusConfigDelete
        }

        if (role == 'bonus-config') {
            return canBonusConfigDelete
        }


        // @ts-ignore
        return (roles.value[role] == 'all');
    }

    function canEdit(role: string) {
        // @ts-ignore
        console.log({val: (roles.value[role])})

        if (role == 'bonus-config') {
            return canBonusConfigEdit
        }

        // @ts-ignore
        return (roles.value[role] == 'edit' || roles.value[role] == 'all');
    }

    function canPayment(role: string[]) {
        return {
            "create": role.includes('create'),
            "view_all": role.includes('view-all'),
            "refund": role.includes('refund'),
            "change_concierge": role.includes('change-concierge'),
            "decline": role.includes('decline'),
            "statistic": role.includes('statistic'),
            "excel": role.includes('excel'),
            "delete": role.includes('delete'),
            "add_services": role.includes('add-services'),
            "gateways": role.includes('gateways'),
            "delete_default_services": role.includes('delete-default-services'),
            "payments_bank_data": role.includes('bank-data'),
            "expresso_bank_data": role.includes('bank-data'),
        };
    }

    function canBonusConfig(role: string[] | any) {
        if (typeof role == 'string' || role == 'none') {
            return {
                "view": false,
                "edit": false,
                "create": false,
                "delete": false,
            };
        }
        return {
            "view": role.includes('view'),
            "edit": role.includes('edit'),
            "create": role.includes('create'),
            "delete": role.includes('delete'),
        };
    }

    const paymentNapr = computed(() => route.path.includes('expreso') ? 'expresso' : 'payments')

    const canPaymentCreate = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).create)
    const canPaymentViewAll = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).view_all)
    const canPaymentStatistic = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).statistic)
    const canPaymentChangeConcierge = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).change_concierge)
    const canPaymentRefund = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).refund)
    const canPaymentDecline = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).decline)
    const canPaymentExcel = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).excel)
    const canPaymentDelete = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).delete)
    const canPaymentAddServices = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).add_services)
    const canPaymentSelectGatewayPayment = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).gateways)
    const canPaymentDeleteDefaultServicesPayment = computed(() => canPayment(item.value.role.admin_access_list[paymentNapr.value] ?? []).delete_default_services)
    const canPaymentForeignBD = computed(() => canPayment(item.value.role.admin_access_list?.payments ?? []).payments_bank_data)
    const canPaymentForeignExpresso = computed(() => canPayment(item.value.role.admin_access_list?.expresso ?? []).expresso_bank_data)

    const canBonusConfigTest = computed(() => canBonusConfig(item.value.role.admin_access_list['bonus-config']))
    const canBonusConfigView = computed(() => canBonusConfig(item.value.role.admin_access_list['bonus-config']).view)
    const canBonusConfigEdit = computed(() => canBonusConfig(item.value.role.admin_access_list['bonus-config']).edit)
    const canBonusConfigCreate = computed(() => canBonusConfig(item.value.role.admin_access_list['bonus-config']).create)
    const canBonusConfigDelete = computed(() => canBonusConfig(item.value.role.admin_access_list['bonus-config']).delete)

    const canBonusCreate = computed(() => canCreate('bonuses'))


    return {
        item,
        isPending,
        userLoad,
        userReload,
        error,
        roles,
        canView,
        canEdit,
        canCreate,
        canDelete,

        canPaymentCreate,
        canPaymentViewAll,
        canPaymentStatistic,
        canPaymentChangeConcierge,
        canPaymentRefund,
        canPaymentDecline,
        canPaymentExcel,
        canPaymentDelete,
        canPaymentAddServices,
        canPaymentSelectGatewayPayment,
        canPaymentDeleteDefaultServicesPayment,
        canPaymentForeignBD,
        canPaymentForeignExpresso,

        canBonusConfigTest,
        canBonusConfigView,
        canBonusConfigEdit,
        canBonusConfigCreate,
        canBonusConfigDelete,

        canBonusCreate,
    }
})
